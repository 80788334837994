"use client";

import Image from "next/image";
import { Allerta_Stencil, Oswald } from "next/font/google";
import { Separator } from "@/components/ui/separator";

const allerta = Allerta_Stencil({
  subsets: ["latin"],
  variable: "--font-sans",
  weight: "400",
});

const oswald = Oswald({
  subsets: ["latin"],
  variable: "--font-sans",
  weight: "200",
});

export default function Custom404() {
  return (
    <div className="flex w-full gap-x-24 justify-center max-w-4xl items-center">
      <div className="flex flex-col gap-y-4">
        <div
          className={`${allerta.className} text-8xl text-center text-[#ff2740]`}
        >
          404
        </div>
        <Separator className="mx-auto" />

        <div
          className={`${oswald.className} text-2xl uppercase text-muted-foreground text-center`}
        >
          Something went wrong
        </div>
        <Separator className="mx-auto" />
        <div className="text-center text-sm md:text-base">
          The dealer said that part is on backorder.
          <br /> Check back with us later to see if we can get our hands on it.
        </div>
      </div>
    </div>
  );
}
